<template>
  <div class="right-section">
    <div class="row header-section">
      <div class="col"></div>
      <div class="col">
        <p class="title-section mb-0">Students</p>
      </div>
      <div class="col">
        <button class="btn btn-light clear-filter-btn"
                type="button"
                @click="clearStudentFilter"
                v-if="selectedStudentId">
          Clear Filter
        </button>
      </div>
    </div>


    <div class="body-section">
      <div class="body-wrapper" v-if="students !=''">

        <!-- Student List -->
        <div class="students-wrapper"
             v-for="(student, index) in students"
             :key="index">
          <div class="row"
               @click="filterStudentContent(student)"
               :class="student.user_id === selectedStudentId ? 'active' : ''">
            <!-- Student Photo -->
            <div class="col-3">
              <img class="profile-photo"
                   :src="student.profile_img_url"
                   alt="profile-photo"
                   v-if="student.profile_img_url" />

              <img class="profile-photo"
                   src="../../../assets/images/sample_student_profile.png"
                   alt="profile-photo"
                   v-else />
            </div>

            <!-- Student Info -->
            <div class="col-9">
              <div class="name-wrapper">
                <span class="float-right remove-student" v-if="isMyClassroom">
                  <i class="fas fa-trash text-danger"
                    @click="showRemoveStudentDialog"></i>
                </span>
                <p class="mb-0 text-capitalize">
                  {{ student.nickname }}
                </p>
                <!-- <p class="small mb-0 mt-0"
                    v-show="student.firstname || student.lastname">
                  {{ student.firstname }} {{ student.lastname }}
                </p> -->
                <p class="grade small text-muted">
                  {{ student.grade_level | levelAbbreviation }}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <hr class="white">
            </div>
          </div>
        </div>

        <slot></slot>
      </div>

      <div class="body-wrapper" v-else>
        <div class="row">
          <div class="col">
            <p class="mb-0 text-center">No Students yet</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FilterMixin from '@/mixins/FilterMixin';

  export default {
    name  : 'StudentList',
    props : {
      students      : Array,
      classroomId   : Number,
      isMyClassroom : Boolean,
    },
    data() {
      return {
        selectedStudentId : null,
      }
    },
    mixins : [
      FilterMixin,
    ],
    methods : {

      /**
       * Filter Approved Content per student
       * @param student
       */
      filterStudentContent(student) {
        this.selectedStudentId = student.user_id;
        this.$emit('filter-content', student);
      },

      /**
       * Clear Filter
       */
      clearStudentFilter() {
        this.selectedStudentId = null;
        this.$emit('clear-filter');
      },

      /**
       * Show Remove Student Confirmation Dialog
       */
      showRemoveStudentDialog() {

        this.$modal.show('dialog', {
          title   : 'Please confirm:',
          text    : ' Are you sure you want to remove this student?',
          buttons : [
            {
              title   : 'Yes',
              handler : () => {
                this.$http.delete('/api/classroom/user', {
                  data : {
                    userId      : this.selectedStudentId,
                    classroomId : this.classroomId,
                  },
                }).then(() => {
                  this.clearStudentFilter();
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Student removed successfully',
                  });
                  this.$emit('removed-student');
                  this.$modal.hide('dialog');
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                });
              },
            },
            { title : 'No' },
          ],
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/components/user/classroom/student-list";
</style>
